import styled from "styled-components";

const Main = (props) => {
  return <Container>Olá Mundo!!!</Container>;
};

const Container = styled.div`
  grid-area: main;
`;

export default Main;
